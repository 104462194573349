import { useState, useEffect } from "react";
import { FaAngleRight, FaCheck } from "react-icons/fa";

function Playlist({
  songList,
  setMessage,
  setIsCreatingNewPlaylist,
  setSelectedPlaylist,
  selectedPlaylist,
}) {
  const [isSelected, setIsSelected] = useState(
    selectedPlaylist.name === songList.name
  );
  const showList = () => {
    //setShowExtendedSongList(true);
    //setSelectedSongList(songList);
    setMessage("");
    setIsCreatingNewPlaylist(false);
    setSelectedPlaylist(songList);
  };

  useEffect(() => {
    setIsSelected(selectedPlaylist.name === songList.name);
  }, [selectedPlaylist, songList]);

  return (
    <div
      style={{
        width: "90%",
        margin: "1vh",
        borderRadius: "0.5vh",
        border: !isSelected ? "1px solid white" : "1px solid #4db193",
        padding: "0.5%",
        backgroundColor: !isSelected ? "transparent" : "#4db193",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        cursor: "pointer",
      }}
      onClick={() => showList()}
    >
      <div style={{ width: "80%" }}>
        <div style={{ color: !isSelected ? "white" : "black" }}>
          {songList.name}
        </div>
        <div style={{ color: !isSelected ? "white" : "black", fontSize: 12 }}>
          {songList.songs?.length} sanger
        </div>
      </div>
      <div
        style={{ width: "10%", display: "flex", justifyContent: "flex-start" }}
      >
        {isSelected ? <FaCheck /> : <FaAngleRight color="white" />}
      </div>
    </div>
  );
}

export default Playlist;
