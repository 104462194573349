import "../App.css";
import NumberBox from "../components/NumberBox";
import { useEffect, useState, useContext, useRef } from "react";
import Team1 from "../components/Team1";
import Team2 from "../components/Team2";
import {
  FaRandom,
  FaTimes,
  FaEye,
  FaEyeSlash,
  FaSignOutAlt,
  FaForward,
} from "react-icons/fa";
import Confetti from "react-confetti";
import dancingGif from "../assets/gifs/dancing.gif";
import AdminDashboard from "../components/AdminDashboard";
import { SongsContext } from "../context/SongsContext";
import SpotifyPlayer from "react-spotify-web-playback";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "../Firebase";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const GameSession = () => {
  const [state, dispatch] = useContext(SongsContext);
  const [sentence, setSentence] = useState("");
  const [words, setWords] = useState([]);
  const [title, setTitle] = useState("");
  const [artist, setArtist] = useState("");
  const [songUri, setSongUri] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [redNumbers, setRedNumbers] = useState([]);
  const [numberOfSongs, setNumberOfSongs] = useState(0);
  const [previousSongs, setPreviousSongs] = useState([]);
  const [showCountdown, setShowCountdown] = useState(false);
  const [showWinner, setShowWinner] = useState(false);
  const [maxScore, setMaxScore] = useState(20);
  const [winner, setWinner] = useState("");
  const [hasFinishedSetup, setHasFinishedSetup] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [team1score, setTeam1score] = useState(null);
  const [team2score, setTeam2score] = useState(null);
  const [team1name, setTeam1name] = useState("Lag 1");
  const [team2name, setTeam2name] = useState("Lag 2");
  const [selectedSongList, setSelectedSongList] = useState([]);
  const [showBoxes, setShowBoxes] = useState([]);
  let { sessionId } = useParams();
  const navigate = useNavigate();
  const previousIndex = usePrevious(currentIndex);

  useEffect(() => {
    document.title = "Beat 4 beat";
  }, []);

  useEffect(() => {
    console.log("Got index update: ", currentIndex);
    if (currentIndex !== null && currentIndex > -1) {
      updateSongLocally();
    }
  }, [currentIndex, previousIndex]);

  useEffect(() => {
    console.log("Listening to session: ", sessionId);
    const unsub = onSnapshot(doc(db, "sessions", sessionId), (doc) => {
      console.log("Current data: ", doc.data());
      let data = doc.data();
      if (data.songList) {
        setSelectedSongList(data.songList);
        setPreviousSongs(data.playedSongs);
        setTeam1score(data.team1score);
        setTeam2score(data.team2score);
        setTeam1name(data.team1Name);
        setTeam2name(data.team2Name);
        setCurrentIndex(data.currentIndex);
        setShowAnswer(data.showAnswer);
        setShowBoxes(data.showBoxes ? data.showBoxes : []);
        setGameStarted(data.gameStarted);
      }
    });

    return () => {
      console.log("Unsubscribing from session: ", sessionId);
      unsub();
    };
  }, [sessionId]);

  const updateSong = () => {
    console.log("UPDATING SONG");
    if (!currentIndex) {
      return;
    }
    const nextIndex = currentIndex + 1;
    //setCurrentIndex(nextIndex);
    //Update song in db: index and playedSongs
    !gameStarted && setGameStarted(true);
    if (nextIndex > selectedSongList?.songs?.length - 1) {
      alert("Tomt for sanger :/");
    } else {
      updateIndex(nextIndex);
      const item = selectedSongList?.songs[nextIndex];
      setSentence(item.text);
      setTitle(item.title);
      setArtist(item.artist);
      setSongUri(item.uri);
      let newPreviousSongs = [...previousSongs];
      newPreviousSongs.push(item);
      setPreviousSongs(newPreviousSongs);
    }
  };

  const updateSongLocally = () => {
    console.log("UPDATING SONG locally to index: ", currentIndex);
    const item = selectedSongList?.songs[currentIndex];
    setSentence(item.text);
    setTitle(item.title);
    setArtist(item.artist);
    setSongUri(item.uri);
  };

  useEffect(() => {
    if (
      selectedSongList.songs?.length > 0 &&
      !gameStarted &&
      currentIndex === -1
    ) {
      updateSong();
      console.log("Should update song to index: ", currentIndex);
    } else {
      console.log(
        "Songlist, gameStarted, currentIndex",
        selectedSongList,
        gameStarted,
        currentIndex
      );
    }
  }, [selectedSongList, currentIndex, gameStarted]);

  useEffect(() => {
    setShowAnswer(false);
    const wordArray = sentence.split(" ");
    setWords(wordArray);
    //Two random numbers need to be red
    const possibleNumbers = [0, 1, 2, 3, 4, 5];
    const randomNumberOne = possibleNumbers.splice(
      Math.floor(Math.random() * possibleNumbers.length),
      1
    );
    const randomNumberTwo = possibleNumbers.splice(
      Math.floor(Math.random() * possibleNumbers.length),
      1
    );
    setRedNumbers([randomNumberOne[0], randomNumberTwo[0]]);
  }, [sentence]);

  const endGame = () => {
    //Clear everything
    setGameStarted(false);
    setCurrentIndex(-1);
    setHasFinishedSetup(false);
    navigate("/setup");
  };

  const updateScore = async (team, newScore) => {
    try {
      const docRef = await doc(collection(db, "sessions"), sessionId);
      //const newScore = team === "team1" ? team1score + 1 : team2score + 1;
      if (team === "team1") {
        await updateDoc(docRef, {
          team1score: newScore,
        });
      } else if (team === "team2") {
        await updateDoc(docRef, {
          team2score: newScore,
        });
      }
      console.log("Score updated with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const updateIndex = async (newIndex) => {
    console.log("Updating index for session ", sessionId);
    try {
      const docRef = await doc(collection(db, "sessions"), sessionId);
      //const newScore = team === "team1" ? team1score + 1 : team2score + 1;
      await updateDoc(docRef, {
        currentIndex: newIndex,
        showAnswer: false,
        showBoxes: [],
        gameStarted: true,
      });
      console.log("Index updated with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <header className="App-header">
        <div
          className="infoHeader"
          style={{
            fontSize: 14,
            width: "100vw",
            height: "5vh",
            backgroundColor: "#3d3d5b",
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              padding: 5,
              //backgroundColor: "red",
              height: "40%",
              width: "10%",
              borderRadius: 5,
              color: "red",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              //border: "1px solid red"
            }}
            onClick={() => {
              let shouldCancel = window.confirm("Avslutte spillet?");
              if (shouldCancel) {
                endGame();
              } else {
                return;
              }
            }}
          >
            <FaSignOutAlt color="red" />
            <div style={{ marginLeft: 5 }}>Avslutt spill</div>
          </div>
          <div
            style={{
              padding: 5,
              height: "40%",
              width: "40%",
            }}
          >
            {currentIndex + 1}/{selectedSongList?.songs?.length} sanger
          </div>
          {/*}
                        <div 
                            style={{
                                cursor: "pointer",
                                padding: 5,
                                //backgroundColor: "red",
                                height: "40%",
                                width: "10%",
                                borderRadius: 5,
                                //border: "1px solid white"
                            }}
                            onClick={() => {
    
                            }}
                        >
                            Regler
                        </div>
                        */}
          <div
            style={{
              width: "10%",
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-end",
              userSelect: "none",
              textAlign: "center",
              marginRight: "2vw",
            }}
          >
            Først til
            <div
              style={{ marginLeft: 7 }}
              contentEditable="true"
              onInput={(e) => setMaxScore(Number(e.currentTarget.textContent))}
            >
              {" "}
              {maxScore}
            </div>
          </div>
        </div>

        <div
          className="allNumbers"
          style={{
            display: "flex",
            flexDirection: "row",
            //minWidth: '80vw',
            //marginLeft: '2.5%',
            height: "25vh",
            //backgroundColor: 'white',
            alignItems: "center",
            justifyContent: "center",
            //backgroundColor: "#23262d",
            marginTop: "5vh",
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          {words.map((word, i, words) => {
            return (
              <NumberBox
                i={i}
                key={word + title + i}
                word={word}
                backgroundColor={redNumbers.includes(i) ? "red" : "blue"}
                words={words}
                showAnswer={showAnswer}
                showBoxes={showBoxes}
              />
            );
          })}
        </div>
        <div style={{ height: "20vh", width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                //minHeight: '20vh',
                //maxHeight: '40vh',
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {showAnswer && (
                <div
                  style={{
                    marginTop: 30,
                    color: "white",
                    fontFamily: "Roboto",
                    height: "70%",
                    minWidth: "30vw",
                    fontSize: "7vh",
                  }}
                >
                  <div>{title.toUpperCase()}</div>
                  <div>{artist}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            height: "30vh",
            width: "95%",
            //padding: '1vh',
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "33%",
              height: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {showCountdown && (
              <>
                <iframe
                  className="video"
                  width={"100%"}
                  height="100%"
                  title="Youtube player"
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/8tAXbkGR_-c?start=9`}
                ></iframe>
                <div
                  style={{ cursor: "pointer", marginTop: "1vh" }}
                  onClick={() => setShowCountdown(false)}
                >
                  <FaTimes />
                </div>
              </>
            )}
            {showWinner && (
              <img
                className="App-logo"
                height="100%"
                src={dancingGif}
                alt="dancing"
              />
            )}
            {!showWinner && !showCountdown && (
              <div
                className="button"
                style={{
                  justifyContent: "space-around",
                  display: "flex",
                  height: "8vh",
                  width: "60%",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "0px solid white",
                  borderRadius: 10,
                  marginBottom: "5vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "space-around",
                    width: "100%",
                  }}
                >
                  {!showAnswer && (
                    <div
                      style={{
                        flexDirection: "row",
                        width: "90%",
                        color: "white",
                        cursor: "pointer",
                        userSelect: "none",
                        //margin: "2vh",
                        //marginTop: "4vh",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                      onClick={() => setShowAnswer(!showAnswer)}
                    >
                      <FaEye size="5vh" />
                      <div style={{ marginLeft: "1vw", fontSize: 30 }}>
                        Vis fasit
                      </div>
                    </div>
                  )}
                  {showAnswer && (
                    <div
                      style={{
                        flexDirection: "row",
                        width: "90%",
                        color: "white",
                        cursor: "pointer",
                        userSelect: "none",
                        //margin: "2vh",
                        //marginTop: "4vh",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                      onClick={() => {
                        setShowAnswer(false);
                        setNumberOfSongs(numberOfSongs + 1);
                        //updateIndex();
                        updateSong();
                      }}
                    >
                      <FaForward size="5vh" />
                      <div style={{ marginLeft: "1vw", fontSize: 30 }}>
                        Neste sang
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {showWinner && (
              <div
                style={{
                  cursor: "pointer",
                  marginTop: "1vh",
                  padding: "1vh",
                }}
                onClick={() => setShowWinner(false)}
              >
                <FaTimes />
              </div>
            )}
          </div>
        </div>
        <div
          className="bottomBar"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            height: "20vh",
            maxHeight: "20vh",
            overflow: "hidden",
            width: "100%",
            borderTopLeftRadius: 50,
            borderTopRightRadius: 50,
          }}
        >
          <Team1
            setWinner={setWinner}
            score={team1score}
            i={"1"}
            maxScore={maxScore}
            showWinner={showWinner}
            setShowWinner={setShowWinner}
            setShowCountdown={setShowCountdown}
            showCountdown={showCountdown}
            teamName={team1name}
            updateScore={updateScore}
          />

          <div style={{ width: 2, height: "12vh", backgroundColor: "white" }} />
          <Team2
            setWinner={setWinner}
            score={team2score}
            i="2"
            maxScore={maxScore}
            showWinner={showWinner}
            setShowWinner={setShowWinner}
            setShowCountdown={setShowCountdown}
            showCountdown={showCountdown}
            initTeamName={team2name}
            updateScore={updateScore}
          />
        </div>
      </header>
      {showWinner && <Confetti numberOfPieces={500} />}
    </>
  );
};

export default GameSession;
