import { useState, useEffect, useContext, useRef } from "react";
import {
  FaPhone,
  FaPhoneSlash,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";
import CounterButton from "./CounterButton";
import useSound from "use-sound";
import victorySong from "../assets/sounds/victorySong.mp3";
import { SongsContext } from "../context/SongsContext";
import nagell from "./../assets/images/nagell.png";

function Team1({
  i,
  setShowCountdown,
  showCountdown,
  showWinner,
  setShowWinner,
  maxScore,
  setWinner,
  localStorageTeamName,
  initTeamName,
  score,
  updateScore,
}) {
  const [state, dispatch] = useContext(SongsContext);
  const [play, { stop }] = useSound(victorySong);
  //const [score, setScore] = useState(0);
  const [usedPhone, setUsedPhone] = useState(false);
  const [teamName, setTeamName] = useState(initTeamName);
  const teamNameRef1 = useRef(initTeamName);
  //const [songIsPlaying, setUsedPhone] = useState(false);

  // const updateScore = (newScore) => {
  //   dispatch({
  //     type: "update_team" + i.toString() + "_score",
  //     newScore: newScore,
  //   });
  //   localStorage.setItem("team" + i.toString() + "score", newScore);
  // };

  useEffect(() => {
    if (score === maxScore) {
      play();
      setShowWinner(true);
      setWinner(i);
    }
  }, [score, maxScore, i, play, setShowWinner, setWinner]);

  useEffect(() => {
    if (initTeamName) {
      teamNameRef1.current.textContent = initTeamName;
    } else {
      teamNameRef1.current.textContent = "Lag 1";
    }
  }, []);

  // useEffect(() => {
  //   localStorage.setItem("team" + i.toString() + "name", teamName);
  // }, [teamName, i]);

  return (
    <div
      style={{
        width: "48%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "1%",
        paddingRight: "1vw",
        //paddingBottom: "1%",
      }}
    >
      <img
        src={nagell}
        alt="nagell"
        height="100%"
        width={"20%"}
        style={{ marginRight: "1vw" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "18vw",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <div
          ref={teamNameRef1}
          style={{
            width: "100%",
            //maxHeight: "8vh",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontSize: "clamp(4vh,4vh, 4vh)",
            marginBottom: 20,
            userSelect: "none",
            textAlign: "left",
            maxLength: "15",
          }}
          contentEditable="true"
          type="text"
          spellCheck="false"
          onInput={(e) => {
            let newValue = e.currentTarget.textContent.slice(0, 14);
            setTeamName(newValue);
          }}
        />
        <div
          style={{
            cursor: "pointer",
            //height: "20%",
            //maxHeight: "20%",
            fontSize: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            setUsedPhone(!usedPhone);
            setShowCountdown(!showCountdown);
          }}
        >
          {!usedPhone ? (
            <FaPhone size="24" color="white" />
          ) : (
            <FaPhoneSlash size="24" color="red" />
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          //marginRight: "1vw",
          //marginLeft: "1vw",
          height: "20vh",
          width: "18vw",
        }}
      >
        <div
          style={{
            fontSize: "10vh",
            height: "10vh",
            width: "10vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            userSelect: "none",
          }}
        >
          <div>{Number(score)}</div>
        </div>
        <div
          style={{
            height: "10vh",
            width: "8vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "none",
          }}
        >
          <div
            className="plusCounterButton"
            onClick={() => {
              let newScore = Number(score) + 1;
              updateScore("team1", newScore);
            }}
          >
            <FaChevronUp />
          </div>
          <div
            className="minusCounterButton"
            onClick={() => updateScore("team1", Number(score) - 1)}
          >
            <FaChevronDown />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team1;
