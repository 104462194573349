import { useState, useEffect } from "react";
import { Textfit } from "react-textfit";
import useSound from "use-sound";
import wrongSound from "../assets/sounds/sfx-defeat2.mp3";

function NumberBox({ word, i, backgroundColor, words, showAnswer, showBoxes }) {
  const [play] = useSound(wrongSound);
  const [isOpened, setIsOpened] = useState(false);
  useEffect(() => {
    setIsOpened(false);
  }, [word, words]);

  useEffect(() => {
    showAnswer && setIsOpened(true);
  }, [showAnswer]);

  useEffect(() => {
    console.log("NumberBox.showBoxes", showBoxes);
    !showAnswer && setIsOpened(showBoxes.includes(i));
  }, [showBoxes, showAnswer, i]);

  return isOpened ? (
    <Textfit
      className="numberBox"
      style={{
        height: "100%",
        width: (90 / words.length).toString() + "vw",
        //backgroundColor: 'white',
        color: isOpened ? backgroundColor : "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: isOpened ? "7vh" : "13vh",
        //fontWeight: 'bold',
        //fontFamily: isOpened ? 'Roboto' : 'Brush Script MT',
        border: "1px solid grey",
        //borderRadius: 10,
        cursor: "pointer",
        userSelect: "none",
      }}
      mode="single"
      onClick={() => {
        setIsOpened(!isOpened);
      }}
    >
      <div style={{ padding: "1vw" }}>{word}</div>
    </Textfit>
  ) : (
    <div
      className="numberBox"
      style={{
        height: "100%",
        width: (90 / words.length).toString() + "vw",
        //backgroundColor: 'white',
        color: isOpened ? backgroundColor : "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: isOpened ? "7vh" : "11vh",
        //fontWeight: 'bold',
        //fontFamily: isOpened ? 'Roboto' : 'Brush Script MT',
        border: "1px solid grey",
        //borderRadius: 10,
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={() => {
        setIsOpened(!isOpened);
        if (backgroundColor === "red") {
          play();
        }
      }}
    >
      <div style={{}}>{i + 1}</div>
    </div>
  );
}

export default NumberBox;
