import { useContext, useEffect, useState } from "react";
import { songLists } from "../assets/songs/songs.js";
import Playlist from "./Playlist";
import ExtendedSonglist from "./ExtendedPlaylist";
import { SongsContext } from "../context/SongsContext";
import CreateNewPlaylistButton from "./CreateNewPlaylistButton.js";
import { addDoc, collection, getDocs, get } from "firebase/firestore";
import { db } from "../Firebase.js";
import CreateNewPlaylist from "./CreateNewPlaylist.js";
import { uuidv4 } from "@firebase/util";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";

function SongPicker({ setMessage }) {
  // const [state, dispatch] = useContext(SongsContext);
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState([]);
  const [allSongs, setAllSongs] = useState([]);
  const [isCreatingNewPlaylist, setIsCreatingNewPlaylist] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("isCreatingNewPlaylist", isCreatingNewPlaylist);
  }, [isCreatingNewPlaylist]);

  useEffect(() => {
    console.log("Songpicker rendering");
    getPlaylists();
    getSongs();
  }, []);

  const getPlaylists = async () => {
    const querySnapshot = await getDocs(collection(db, "playlists"));
    let newPlaylists = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      newPlaylists.push(data);
    });
    setPlaylists(newPlaylists);
  };

  const getSongs = async () => {
    const querySnapshot = await getDocs(collection(db, "songs"));
    let newAllSongs = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      newAllSongs.push(data);
    });
    setAllSongs(newAllSongs);
  };

  const createBeatForBeatSession = async () => {
    console.log("Create beat for beat session");
    console.log("Selected playlist: ", selectedPlaylist);

    try {
      const newSession = {
        songList: selectedPlaylist,
        team1score: 0,
        team2score: 0,
        currentIndex: 0,
        team1name: "Lag 1",
        team2name: "Lag 2",
        isGameActive: true,
        playedSongs: [],
      };
      const docRef = await addDoc(collection(db, "sessions"), newSession);
      console.log("Session startet with document ID: ", docRef.id);
      setSessionId(docRef.id);
      //Generate QR code to scan admin panel on mobile
    } catch (e) {
      console.error("Error starting session: ", e);
    }
  };

  return (
    <div
      style={{
        maxWidth: "95vw",
        backgroundColor: "#161f33",
        maxHeight: "75vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2 style={{ color: "white" }}>
        {isCreatingNewPlaylist ? "Ny spilleliste" : "Velg spilleliste"}
      </h2>
      <div
        style={{
          backgroundColor: "#161f33",
          maxHeight: "65vh",
          display: "flex",
          flexDirection: "row",
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
          paddingTop: 0,
        }}
      >
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            width: "20%",
            height: "100%",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            borderRight: "1px solid white",
            minHeight: "50vh",
          }}
        >
          <h3 style={{ color: "white", textAlign: "left", marginLeft: 30 }}>
            Spillelister
          </h3>
          <CreateNewPlaylistButton
            key="newPlayList"
            setIsCreatingNewPlaylist={setIsCreatingNewPlaylist}
            isCreatingNewPlaylist={isCreatingNewPlaylist}
            setSelectedPlaylist={setSelectedPlaylist}
          />
          {playlists.map((playlist) => (
            <Playlist
              key={playlist.name}
              songList={playlist}
              setMessage={setMessage}
              setSelectedPlaylist={setSelectedPlaylist}
              selectedPlaylist={selectedPlaylist}
              setIsCreatingNewPlaylist={setIsCreatingNewPlaylist}
            />
          ))}

          {/*<AddSong />*/}
        </div>
        {!isCreatingNewPlaylist && selectedPlaylist?.name && (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "80%",
              maxHeight: "20%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <ExtendedSonglist songList={selectedPlaylist} />
          </div>
        )}
        {isCreatingNewPlaylist && (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "80%",
              maxHeight: "20%",
              padding: 20,
              paddingTop: 0,
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <CreateNewPlaylist
              setPlaylists={setPlaylists}
              playlists={playlists}
              allSongs={allSongs}
              setSelectedPlaylist={setSelectedPlaylist}
              setIsCreatingNewPlaylist={setIsCreatingNewPlaylist}
            />
          </div>
        )}
      </div>
      {selectedPlaylist.name && !sessionId ? (
        <div
          key="startSession"
          onClick={createBeatForBeatSession}
          style={{
            height: "4vh",
            width: "20%",
            backgroundColor: "#4db193",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
            cursor: "pointer",
            padding: 5,
            marginTop: 10,
            fontSize: 18,
            alignSelf: "center",
            position: "absolute",
            right: 20,
            bottom: 20,
          }}
        >
          Start økt
        </div>
      ) : null}

      {sessionId ? (
        <div>
          <div
            style={{
              padding: 10,
              backgroundColor: "white",
              width: 100,
              height: 100,
              position: "absolute",
              bottom: 150,
              right: 100,
            }}
          >
            <QRCode
              size={100}
              value={"https://b4b.gameguru.no/control/" + sessionId}
            />
          </div>
          <div
            style={{
              padding: 10,
              position: "absolute",
              color: "white",
              bottom: 100,
              right: 150,
            }}
          >
            Scan QR-koden med mobilen, så kan du styre spillet derfra ✌️
          </div>
        </div>
      ) : null}

      {sessionId ? (
        <div
          key="goToGame"
          onClick={() => navigate("../game/" + sessionId, { replace: true })}
          style={{
            height: "4vh",
            width: "20%",
            backgroundColor: "#4db193",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
            cursor: "pointer",
            padding: 5,
            marginTop: 10,
            fontSize: 18,
            alignSelf: "center",
            position: "absolute",
            right: 20,
            bottom: 20,
          }}
        >
          Gå til økt
        </div>
      ) : null}
    </div>
  );
}

export default SongPicker;
