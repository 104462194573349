const PlaylistInput = ({ value, onChange, placeholder, extraStyle }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      style={{
        width: "90%",
        height: "3vh",
        margin: "0.5vh",
        backgroundColor: "transparent",
        color: "white",
        border: "0.5px solid white",
        borderRadius: "0.5vh",
        padding: 5,
        ...extraStyle,
      }}
    />
  );
};

export default PlaylistInput;
