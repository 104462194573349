import { useState } from "react";
import BrowseSongsSong from "./BrowseSongsSong";

const BrowseSongs = ({ newSongs, setNewSongs, allSongs }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 20,
      }}
    >
      <div style={{ color: "white", width: "90%", textAlign: "left" }}>
        Eller velg fra listen
      </div>
      {allSongs.map((song, i) => (
        <BrowseSongsSong
          newSongs={newSongs}
          setNewSongs={setNewSongs}
          key={song.title + i}
          song={song}
        />
      ))}
    </div>
  );
};

export default BrowseSongs;
