import { addDoc, collection } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../Firebase";
import AddNewSong from "./AddNewSong";
import BrowseSongs from "./BrowseSongs";
import PlaylistInput from "./PlaylistInput";
import SelectedSongs from "./SelectedSongs";
import Song from "./Song";

function CreateNewPlaylist({
  allSongs,
  playlists,
  setPlaylists,
  setSelectedPlaylist,
  setIsCreatingNewPlaylist,
}) {
  const [newSongs, setNewSongs] = useState([]);
  const [newPlaylistName, setNewPlaylistName] = useState("");
  const [createSuccess, setCreateSuccess] = useState(false);

  useEffect(() => {
    console.log("newSongs changed!", newSongs);
  }, [newSongs]);

  const addNewPlaylist = async () => {
    if (newPlaylistName === "") {
      alert("Du må velge et navn på spillelisten");
      return;
    }
    if (newSongs.length === 0) {
      alert("Du må velge minst én sang for å opprette en spilleliste");
      return;
    }
    console.log("Add new playlist with these songs: ", newSongs);
    try {
      const newPlaylist = { name: newPlaylistName, songs: newSongs };
      const docRef = await addDoc(collection(db, "playlists"), newPlaylist);
      console.log("Document written with ID: ", docRef.id);
      setNewSongs([]);
      setCreateSuccess(true);
      //Listen to changes in playlists
      setPlaylists([...playlists, newPlaylist]);
      //setSelectedPlaylist to new playlist
      setSelectedPlaylist(newPlaylist);
      setIsCreatingNewPlaylist(false);
    } catch (e) {
      console.error("Error adding document: ", e);
      setCreateSuccess(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#161f33",
        maxHeight: "80vh",
        //overflowY: "scroll",
      }}
    >
      <PlaylistInput
        extraStyle={{
          fontSize: 20,
          fontWeight: "bold",
          width: "40%",
          marginBottom: 10,
        }}
        placeholder="Navn på spilleliste"
        value={newPlaylistName}
        onChange={(e) => setNewPlaylistName(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "20%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            maxHeight: "80vh",
            marginBottom: 10,
          }}
        >
          <AddNewSong setNewSongs={setNewSongs} newSongs={newSongs} />
          <BrowseSongs
            allSongs={allSongs}
            setNewSongs={setNewSongs}
            newSongs={newSongs}
          />
        </div>
        {newSongs.length > 0 ? (
          <SelectedSongs
            addNewPlaylist={addNewPlaylist}
            newSongs={newSongs}
            setNewSongs={setNewSongs}
          />
        ) : null}
      </div>
    </div>
  );
}

export default CreateNewPlaylist;
