import { useState, useContext, useEffect } from "react";
import { FaPlus, FaCheck, FaMusic } from "react-icons/fa";
import { SongsContext } from "../context/SongsContext";

function Song({ song, newSongs, setNewSongs }) {
  return (
    <div
      style={{
        width: "20%",
        margin: "0.5vh",
        borderRadius: "0.5vh",
        border: "1px solid white",
        padding: "0.5vh",
        backgroundColor: "#161f33",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        position: "relative",
        overflow: "hidden",
        //cursor: "pointer",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: 2,
          }}
        >
          <FaMusic color={"white"} />
          <div
            style={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: 10,
            }}
          >
            <div style={{ fontSize: 14 }}>{song.title}</div>
            <div style={{ fontSize: 12 }}>{song.artist}</div>
          </div>
        </div>

        <div
          style={{
            color: "white",
            fontSize: 12,
            marginBottom: 2,
          }}
        >
          "{song.text}"
        </div>
      </div>
    </div>
  );
}

export default Song;
