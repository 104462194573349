import { FaPlus, FaCheck } from "react-icons/fa";

function CreateNewPlaylistButton({
  key,
  setIsCreatingNewPlaylist,
  isCreatingNewPlaylist,
  setSelectedPlaylist,
}) {
  const createNewList = () => {
    setIsCreatingNewPlaylist(true);
    setSelectedPlaylist([]);
  };

  return (
    <div
      style={{
        width: "90%",
        margin: "1vh",
        borderRadius: "0.5vh",
        border: !isCreatingNewPlaylist
          ? "1px solid white"
          : "1px solid #4db193",
        padding: "0.5%",
        backgroundColor: !isCreatingNewPlaylist ? "transparent" : "#4db193",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        cursor: "pointer",
      }}
      onClick={() => createNewList()}
    >
      <div style={{ width: "80%" }}>
        <div style={{ color: !isCreatingNewPlaylist ? "white" : "black" }}>
          Lag ny spilleliste
        </div>
        <div
          style={{
            color: !isCreatingNewPlaylist ? "white" : "black",
            fontSize: 12,
          }}
        >
          Velg sanger selv!
        </div>
      </div>
      <div
        style={{ width: "10%", display: "flex", justifyContent: "flex-start" }}
      >
        {isCreatingNewPlaylist ? <FaCheck /> : <FaPlus color="white" />}
      </div>
    </div>
  );
}

export default CreateNewPlaylistButton;
