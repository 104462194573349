import { useState, useEffect } from "react";
import PlaylistInput from "./PlaylistInput";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { v4 as uuidv4 } from "uuid";
import { songLists, songs } from "../assets/songs/songs";
import { FaPlus } from "react-icons/fa";

const AddNewSong = ({ newSongs, setNewSongs }) => {
  const [title, setTitle] = useState("");
  const [artist, setArtist] = useState("");
  const [text, setText] = useState("");
  const [songID, setSongID] = useState("");

  useEffect(() => {
    //Generate unique id for song
    let newSongID = uuidv4();
    console.log("newSongID", newSongID);
    setSongID(newSongID);
  }, []);

  const addNewSong = async () => {
    try {
      const docRef = await addDoc(collection(db, "songs"), {
        title: title,
        artist: artist,
        text: text,
        songID: songID,
      });
      console.log("Document written with ID: ", docRef.id);
      setNewSongs([...newSongs, { title: title, artist: artist, text: text }]);
      setTitle("");
      setArtist("");
      setText("");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  //   const addAllSongs = async () => {
  //     const allSongs = [];
  //     songLists.forEach((list) => {
  //       list.songs.forEach((song) => {
  //         if (!allSongs.includes(song.title)) {
  //           allSongs.push(song.title);
  //           try {
  //             const docRef = addDoc(collection(db, "songs"), {
  //               title: song.title,
  //               artist: song.artist,
  //               text: song.text,
  //               songID: uuidv4(),
  //             });
  //             console.log("Document written with ID: ", docRef.id);
  //           } catch (e) {
  //             console.error("Error adding document: ", e);
  //           }
  //         }
  //       });
  //     });
  //   };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ color: "white", width: "90%", textAlign: "left" }}>
        Legg til ny sang
      </div>
      <PlaylistInput
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Tittel"
      />
      <PlaylistInput
        placeholder="Artist"
        value={artist}
        onChange={(e) => setArtist(e.target.value)}
      />
      <PlaylistInput
        placeholder="Ord bak lukene (5-6 ord, en setning fra sangen)"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <div
        onClick={addNewSong}
        style={{
          height: "2vh",
          width: "60%",
          backgroundColor: "#4db193",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 5,
          cursor: "pointer",
          padding: 5,
          marginTop: 5,
          fontSize: 14,
          overflow: "hidden",
        }}
      >
        <FaPlus color="black" />
        Legg til sang
      </div>
    </div>
  );
};

export default AddNewSong;
