import { useState, useEffect } from "react";
import { Textfit } from "react-textfit";
import useSound from "use-sound";
import wrongSound from "../assets/sounds/sfx-defeat2.mp3";

function NumberBoxMobile({
  word,
  i,
  backgroundColor,
  words,
  showAnswer,
  updateShowBoxes,
}) {
  const [play] = useSound(wrongSound);
  const [isOpened, setIsOpened] = useState(false);
  useEffect(() => {
    setIsOpened(false);
  }, [word, words]);

  useEffect(() => {
    showAnswer && setIsOpened(true);
  }, [showAnswer]);

  return isOpened ? (
    <Textfit
      className="numberBox"
      style={{
        height: "45%",
        width: "30vw",
        margin: "0.5vw",
        //backgroundColor: 'white',
        color: isOpened ? backgroundColor : "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: isOpened ? "5vh" : "13vh",
        //fontWeight: 'bold',
        //fontFamily: isOpened ? 'Roboto' : 'Brush Script MT',
        border: "1px solid grey",
        //borderRadius: 10,
        cursor: "pointer",
        userSelect: "none",
      }}
      mode="single"
      onClick={() => {
        //setIsOpened(!isOpened);
        //Update in db
      }}
    >
      <div style={{ padding: "1vw" }}>{word}</div>
    </Textfit>
  ) : (
    <div
      className="numberBox"
      style={{
        height: "45%",
        width: "30vw",
        margin: "0.5vw",
        //backgroundColor: 'white',
        color: isOpened ? backgroundColor : "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: isOpened ? "5vh" : "11vh",
        //fontWeight: 'bold',
        //fontFamily: isOpened ? 'Roboto' : 'Brush Script MT',
        border: "1px solid grey",
        //borderRadius: 10,
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={() => {
        setIsOpened(!isOpened);
        updateShowBoxes(i);
        if (backgroundColor === "red") {
          play();
        }
      }}
    >
      <div style={{}}>{i + 1}</div>
    </div>
  );
}

export default NumberBoxMobile;
